<template>
  <div class="transition-wrapper">
    
    <div class="about-wrapper">
    </div>
      
        <b-container>
          <div class="content about">
            <div class="content-wrapper">
              <b-row>
                <b-col>
                  <div class="heading-image">
              
                    <div class="about-image">
                        <img v-if="pic.length > 0" :src="pic">     
                    </div><!--.about-image-->
                    <div>
                      <h2>{{heading}}</h2>
                    </div>
                  </div><!--heading image-->
                </b-col>              
              </b-row>
              <b-row>
                <b-col md="8" lg="9">
                  <div v-html='content' class="about-text"></div>
                </b-col>
              </b-row>
            
              <a class="link-button text-blau" href="mailto:hallo@nikolaigust.de">Feel Free to Contact Me</a>
              </div><!--content-->
          </div>
        </b-container>
      
  
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
    props:["mobile"],
    name: 'About',
    components: {

    },
    data () {
        return {
           about: []
        }
    },
    computed: {
        ...mapGetters([
                'windowWidth'
            ]),
      heading: function () {
        if (this.about[0]) {
          return this.about[0].title.rendered
        } else {
          return ""
        }
      },
      content: function () {
        if (this.about[0]) {
          return this.about[0].content.rendered
        } else {
          return ""
        }
      },
      pic: function () {
        if(this.about[0]) {
          if (this.about[0]._embedded['wp:featuredmedia'][0]) {
            return this.about[0]._embedded['wp:featuredmedia'][0].source_url
          } else {
              return ""
          }
        } else {
            return ""
        }
        
      }
    },
    created () {
        axios.get('https://wordpress.nikolaigust.de/wp-json/wp/v2/pages?slug=about&_embed' )
        .then(res => {          
            this.about = res.data
        })
    }
}

</script>

<style lang="scss">


.about-wrapper {
  width: 110%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #003fff;
  overflow-y: scroll;

}


  .about {
    color: #fff;
    z-index: 1001;
    //position: relative;

    h1 {
      display: none;
      
    }

    h2 {
      font-family: 'LeagueGothic', sans-serif;
      font-style: italic;
      font-size: 25vh;
      line-height: 25vh;
      z-index: 100;
      margin-bottom: 25px;

      @media screen and (max-width: 767px) {
        font-size: 22.5vh;
      line-height: 22.5vh;
      }
    }

    p {
      font-size: 6vh;
      line-height: 8vh;
      margin-bottom: 0;
      z-index: 100;
    }

     @media screen and (max-width: 767px) {
        margin-bottom: 100px;

        p {
          font-size: 32px;
          line-height: 48px;
        }
    }

    @media screen and (min-width: 1200px) {
        h2 {
          font-size: 272px;
          line-height: 272px;
        } 

    }

    .about-text {
      z-index: 100;
    }

    .content-wrapper {
      position: relative;
      width: 100%;     
      z-index: 1011;
    }

    .heading-image {
      position: relative;

      h2 {
         @media screen and (max-width: 767px) {
          position: relative;
          top: -80px;
          margin-bottom: -60px;
        }
      }

      .about-image {
        position: absolute;
        top: 10px;
        right: 15px;
        height: auto;
        z-index: -1;
        //max-height: 50vh;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        right: 0;

        @media screen and (max-width: 767px) {
          max-width: 100%;
          position: static;
        }

        img {
          border: 2px solid #fff;
          height: auto;
          width: 100%;
        }
      }
    }

    .link-button { //Übergeordnetes Button Desin in custom.scss
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      transition: box-shadow 0.3s ease;

      &:hover {
        text-decoration: none;
        box-shadow: 0 0 8em 0 rgba( 0, 0, 0, 0.6);
      }

      @media screen and (max-width: 767px) {
        position: relative;
        left: 0;
        top: 30px;
        margin-bottom: -30px;
      }
    }
  }


</style>
